import Form from '../components/Form';
import Result from '../components/Result';
import separatorIcon from '../assets/images/separator.svg';

const Main = () => {
  return (
    <>
    <div className="mac-top-section">
      <h1 style={{paddingBottom: '48px'}}>Mortgage Affordability Calculator</h1>
      <p style={{ fontWeight: 300, color: '#AE1D57', fontSize: '28px', paddingBottom: '48px', lineHeight: '30px'}}>Enter a few details into our calculator below, and find out how much you could be able to borrow for your mortgage.</p>
    </div>
	<div className="container">
		<div className="mac-inner-content">
		  <div className="row">
			  <div className="col-md-6">
				  <Form/>
			  </div>
			  <div className="col-md-6 mac-income-part">
				  <Result/>
			  </div>
		  </div>
		</div>
	</div>
    </>
  );
}

export default Main;
