import React, {useState, useEffect} from 'react';
import { default as NumberFormat } from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux'
import { calculateMortgage } from '../slices/mortgage';
import homeIcon from  "../assets/images/home.png";
import infoIcon from  "../assets/images/info.png";
import tickIcon from  "../assets/images/tick.png";
import {render} from "@testing-library/react";


const  Form = () => {
  const dispatch = useDispatch();
  const [income, setIncome] = useState <number | null>(null);
  const [bonus, setBonus] = useState <number | null> (null);
  const [hasIncome, setHasIncome] = useState(false);
  const [hasBonus, setHasBonus] = useState(false);
  const [mortgageType, setMortgageType] = useState <string>('single');


  const handleInputValidation = (values : any) => {
    if(!values.value.length){
      return true;
    }

    return values.value.length < 9;
  }

  const handleMortgageType = (type: string ) => {
    setMortgageType(type);
  };

  const handleIncomeChange = (val : number | null = null) => {
    if(!val){
      setIncome(null);
    }

    if(val && val >= 0 && val.toString().length < 9 ){
      setIncome(val);
    }

  };

  const handleBonusChange = (val : number | null = null) => {
    if(!val){
      setBonus(null);
    }

    if(val && val >= 0 && val.toString().length < 9){
      setBonus(val);
    }
  }

  useEffect(()=>{
      dispatch(calculateMortgage(income, bonus));
  },[dispatch, income, bonus]);

  function renderCheckbox(label: string, type: string)
  {
      if(mortgageType === type)
      {
          return (
              <div onClick={() => handleMortgageType(type)} style={{ display: 'flex', flexDirection: 'row'}}>
                  <p style={{paddingRight: '12px'}}>{ label }</p>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '24px', height: '24px', borderRadius: '3px', border: '1px #AE1D57 solid', background: '#AE1D57' }}>
                      <img style={{ width: '14px', height: '10px' }} src={tickIcon} />
                  </div>
              </div>
          );
      }

      return (
          <div onClick={() => handleMortgageType(type)} style={{ display: 'flex', flexDirection: 'row'}}>
              <p style={{paddingRight: '12px'}}>{ label }</p>
              <div style={{ width: '24px', height: '24px', borderRadius: '3px', border: '1px #AE1D57 solid' }}></div>
          </div>
      );
  }

  return (
    <>
    <div className="mac-about-your-outer">
        <div className="mac-about-your" style={{ paddingBottom: '64px'}}>
            <div className="mac-abt-ur-head">
                <img src={homeIcon} className="mac-home-icon" />
            </div>
            <h2>About your mortgage </h2>
        </div>
        <div className="mac-mortagage-form-section">
            <h3>Are you applying for a single or joint mortgage?</h3>
            <form className="mac-form-inner">
                <div className="mac-form-radio-btn" style={{ display: 'flex', flexDirection: 'row'}}>
                    <label className="mac-radio" htmlFor="single" style={{ display: 'flex', flexDirection: 'row', paddingRight: '48px'}}>
                        {renderCheckbox("Single", 'single')}
                     </label>
                    <label className="mac-radio" htmlFor="joint" style={{ display: 'flex', flexDirection: 'row', paddingRight: '48px'}}>
                        {renderCheckbox("Joint",  'joint')}
                    </label>
                  </div>
                  <div className="mac-frm-input">
                          <div className="mac-input-box">
                            <label htmlFor="yearly-income">What’s your yearly income? </label>
                            <span>
                            <NumberFormat id="yearly-income" name="year" thousandSeparator={true} prefix={"£ "} value={income} isAllowed={handleInputValidation} decimalScale={2} allowNegative={false} onValueChange={(values) => {handleIncomeChange(values.floatValue);}} placeholder={"£ "} />
                            <img src={infoIcon} className="mac-info-icon" data-tip="If joint, please provide the total amount you both earn" />
                            <ReactTooltip place="top" type="info" effect="solid" />
                            </span>
                          </div>
                          <div className="mac-input-box">
                              <label htmlFor="regular-bonus">Do you receive a regular bonus? If so, how much is this per year?   </label>
                              <NumberFormat id="regular-bonus" name="year" thousandSeparator={true} prefix={"£ "} value={bonus} isAllowed={handleInputValidation} decimalScale={2} allowNegative={false} onValueChange={(values) => {handleBonusChange(values.floatValue)}} placeholder={"£ "} />
                        </div>
                  </div>
            </form>
        </div>
        </div>
    </>
  );
}

export default Form;
