import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.css';
import Main from './pages/Main';

function App() {
  return (
    <>
      <Main/>
    </>
  );
}

export default App;
